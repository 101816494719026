<template>
    <v-container>
      <v-flex xs12 sm12 md12 lg12>
        
      <v-data-table
        :headers="headers"
        :search="search"
        :items="itemsBitacora"
        sort-by="hora_inicio"
        class="elevation-1 d-block"
      >
    
    
        <template v-slot:top>
          <v-toolbar  flat color="opcion1">
            <v-toolbar-title>Bitacora Trabajo</v-toolbar-title>
            <v-divider class="mx-4"  inset vertical></v-divider>
    
             <v-flex  xs4 sm4 md3 lg3>
              <v-text-field  justify-center  v-model="search"  append-icon="search" 
              label="Busqueda" single-line hide-details></v-text-field> 
            </v-flex>
    

    
            <v-spacer></v-spacer>
    
    
            <v-btn color="success" @click="listar()" class="mx-1 mb-1"> 
              <v-icon dark>sync</v-icon>
            </v-btn>
    
    
            <v-dialog  v-model="dialog" persistent  max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn  color="primary"  dark  class="mb-2" v-bind="attrs"  v-on="on">
                  <v-icon dark>add</v-icon>
                </v-btn>
              </template>
              <v-card>
    
                <v-toolbar color="red">
                  <v-toolbar-title class="white--text">
                    {{ formTitle }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon dark @click="dialog=false">close</v-icon>
                </v-toolbar>
    
    
    
    
    
                <v-card-text>
    
    
                  <v-tabs class="pt-0 mt-0" color="deep-purple accent-4" right>
                  <!--v-tab>Datos Orden Trabajo</v-tab-->
                  <v-tab>Datos Vehiculo</v-tab>
                  <v-tab v-show="formTitle=='Edicion bitacora'">Diagramas</v-tab>
    
                <!--v-tab-item>
                  <v-container>
    
                    <v-row>
    
                      <v-col  cols="12" sm="5"  md="3">
                    
                          <v-menu
                          ref="menuFecha_bitacora"
                          v-model="menuFecha_bitacora"
                          :close-on-content-click="false"
                          :return-value.sync="date_bitacora"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="editedItem.fecha"
                                  label="Fecha inicio"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[rules.required]"
                                  :error-messages="errorMessages"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="editedItem.fecha" locale="es"
                              no-title  @input="menuFecha_bitacora = false" scrollable>
                              </v-date-picker>
                        </v-menu>
    
    
                      </v-col>
    
    
    
                
    
                         <v-col cols="12" sm="4" md="3">
                              <v-menu
                                ref="menu"
                                v-model="menu_hora_esta"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="time"
                                color="indigo"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="editedItem.hora_establecida"
                                    label="Hora Establecida."
                                    prepend-icon="timer"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.required]"
                                    :error-messages="errorMessages"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                color="indigo"
                                v-if="menu_hora_esta"
                                v-model="editedItem.hora_establecida"
                                full-width
                                @click:minute="$refs.menu.save(time)"
                                ></v-time-picker>
                            </v-menu>
    
                      </v-col>
    
    
    
    
    
    
    
                  
    
    
                        <v-col  cols="12" sm="6" md="6">
                      
                          <v-autocomplete
                          v-model="editedItem.id_bitacora_cliente"
                          :items="items_cliente"
                          label="Cliente"
                          item-value="value"
                          item-text="text"
                          persistent-hint
                          prepend-icon="face"
                          :rules="[rules.required]"
                          :error-messages="errorMessages"
                          @change="actualizarContactos()"
                        >
                          </v-autocomplete>
                      </v-col>
    
    
                       <v-col  cols="12" sm="6" md="4">
                    
                         <v-select
                                v-model="editedItem.id_tipo_trabajo"
                                :items="items_TiposTrabajos"
                                prepend-icon="home_repair_service"
                                :rules="[rules.required]"
                                :error-messages="errorMessages"
                                label="Tipo Trabajo"
                                @change="HabilitarcamposTrabajo()"
                            ></v-select>
    
                      </v-col>
    
    
                          <v-col v-show="ver_campos_traslado==true" cols="12" sm="6" md="3">
                             <v-text-field
                              v-model="editedItem.placa_anterior"
                              label="Placa anterior"
                              prepend-icon="backspace"
                            ></v-text-field>
                          </v-col>
    
    
                      
    
                       <v-col  cols="12" sm="6" md="4">
                       
    
                          <v-select
                                v-model="editedItem.status"
                                :items="items_status"
                                prepend-icon="label"
                                :rules="[rules.required]"
                                :error-messages="errorMessages"
                                label="status"
                            ></v-select>
    
    
                      </v-col>
    
    
                        <v-col cols="12" sm="6" md="4" class="pt-0">
                             
                        <v-btn x-small class="d-inline ml-15" @click="nuevoContacto()">nuevo</v-btn>
                         <v-combobox
                            class="d-inline"
                            v-model="editedItem.contacto"
                            :items="items_contacto"
                            label="Contacto"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                        ></v-combobox>
    
                        
    
    
    
                      </v-col>
    
    
                             <v-col cols="12" sm="3" md="3">
                              <v-menu
                                ref="menu"
                                v-model="menu_hora_llegada"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="time"
                                color="indigo"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="editedItem.hora_llegada_tecnico"
                                    label="Llegada Tenico"
                                    prepend-icon="shutter_speed"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                color="indigo"
                                v-if="menu_hora_llegada"
                                v-model="editedItem.hora_llegada_tecnico"
                                full-width
                                @click:minute="$refs.menu.save(time)"
                                ></v-time-picker>
                            </v-menu>
    
                      </v-col>
    
    
    
    
    
    
                       <v-col cols="12" sm="3" md="3">
                              <v-menu
                                ref="menu"
                                v-model="menu_hora_inicio"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="time"
                                color="indigo"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="editedItem.hora_inicio"
                                    label="Inicio Hora"
                                    prepend-icon="av_timer"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                color="indigo"
                                v-if="menu_hora_inicio"
                                v-model="editedItem.hora_inicio"
                                full-width
                                @click:minute="$refs.menu.save(time)"
                                ></v-time-picker>
                            </v-menu>
    
                      </v-col>
    
    
    
    
    
                       <v-col cols="12" sm="3" md="3">
                              <v-menu
                                ref="menu"
                                v-model="menu_hora_fin"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="time"
                                color="indigo"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="editedItem.hora_fin"
                                    label="Fin Hora"
                                    prepend-icon="timelapse"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-time-picker
                                color="indigo"
                                v-if="menu_hora_fin"
                                v-model="editedItem.hora_fin"
                                full-width
                                @click:minute="$refs.menu.save(time)"
                                ></v-time-picker>
                            </v-menu>
    
                      </v-col>
    
    
    
    
    
                         <v-col cols="12" sm="12" md="12">
                                  <v-btn x-small class="d-inline ml-15" @click="nuevaDireccion()">nueva</v-btn>
                            <v-combobox
                                v-model="editedItem.direccion"
                                :items="items_direcciones"
                                label="Direccion"
                                prepend-icon="apartment"
                            ></v-combobox>
    
                      </v-col>
    
    
                         <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.descripcion_trabajo"
                          label="Descripcion trabajo"
                          prepend-icon="description"
                        ></v-text-field>
                      </v-col>
    
                      
    
    
                      <v-col  cols="12" sm="6" md="4">
                         <v-select
                                v-model="editedItem.etiqueta"
                                :items="items_etiquetas"
                                prepend-icon="local_offer"
                                :clearable="true"
                                label="Etiqueta"
                            ></v-select>
                      </v-col>
    
    
    
                         <v-col cols="12" sm="8" md="8">
    
                              <v-select
                              class="pt-0"
                                v-model="editedItem.id_tecnicos"
                                :items="items_tecnicos"
                                attach
                                chips
                                label="Tecnicos"
                                multiple
                              ></v-select>
    
                      </v-col>
    
    
                      <v-col  cols="12" sm="12" md="12">
                        
                         <v-textarea
                          clearable
                           clear-icon="cancel"
                           rows="2"
                          label="Observacion Operador"
                          v-model="editedItem.observacion_operador"
                           prepend-icon="comment"
                        ></v-textarea>
    
                      </v-col>
    
                    <v-col cols="12" sm="6" md="6">
                        <v-checkbox
                          v-model="editedItem.procesado_avl"
                          label="Procesado en AVL"
                        ></v-checkbox>
                      </v-col>
    
                       <v-col cols="12" sm="5" md="5">
                          <span class="d-inline-flex mx-2 mt-5"><strong>Estado Bitacora:</strong></span>
                        <input type="radio" value="1" v-model="editedItem.activo" class="d-inline mx-1">Activo
                        <input type="radio" value="0" v-model="editedItem.activo" class="d-inline mx-1">Inactivo
     
                      </v-col>
    
    
    
                    </v-row>
                  </v-container>
                </v-tab-item-->
    
    
                  <v-tab-item>
                   <v-container>
                     <v-row>
    
    
                       <v-col  cols="12" sm="6" md="6">
                    
                            <v-text-field class="pr-1"
                              v-model="id_avl_buscar"
                              label="Busqueda ID AVL"
                            ></v-text-field>
    
                        </v-col>
    
                        <v-col  cols="12" sm="6" md="6">
    
                            <v-btn class="mt-2" color="primary" @click="BuscarDatosId()">
                              <v-icon >manage_search</v-icon>
                            </v-btn>
                        </v-col>
                        
    
    
                      <v-col  cols="12" sm="5" md="3">
                      
                          <v-select
                                v-model="editedItem.id_tipo_equipo"
                                :items="items_TiposEquipos"
                                prepend-icon="beenhere"
                                label="Tipo equipo"
                            ></v-select>
    
    
                      </v-col>
    
    
                      
                      <v-col  cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.id_avl"
                          label="id_avl"
                          prepend-icon="tag"
                        ></v-text-field>
                      </v-col>
    
    
    
                         <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.nombre"
                          label="nombre"
                          prepend-icon="label_important"
                        ></v-text-field>
                      </v-col>
    
    
    
    
                      <v-col  cols="12" sm="5" md="3">
                        <v-text-field
                          v-model="editedItem.placa"
                          label="placa"
                          prepend-icon="closed_caption"
                        ></v-text-field>
                      </v-col>
    
    
                          <v-col v-if="ver_campos_cambio==true" cols="12" sm="6" md="3">
                             <v-text-field
                              v-model="editedItem.serie_anterior"
                              label="Serie anterior"
                              prepend-icon="fast_rewind"
                            ></v-text-field>
                          </v-col>
    
    
                            <v-col v-show="ver_campos_cambio==true" cols="12" sm="6" md="3">
                             <v-text-field
                              v-model="editedItem.imei_anterior"
                              label="Imei anterior"
                              prepend-icon="undo"
                            ></v-text-field>
                          </v-col>
    
    
                       <v-col  cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.flota"
                          label="Flota"
                          prepend-icon="commute"
                        ></v-text-field>
                      </v-col>
    
    
    
                         <v-col  cols="12" sm="5" md="3">
                        <v-text-field
                          v-model="editedItem.estilo"
                          label="estilo"
                          prepend-icon="directions_car_filled"
                        ></v-text-field>
                      </v-col>
    
                          <v-col  cols="12" sm="5" md="3">
                        <v-text-field
                          v-model="editedItem.marca"
                          label="marca"
                          prepend-icon="branding_watermark"
                        ></v-text-field>
                      </v-col>
    
                          <v-col  cols="12" sm="5" md="3">
                        <v-text-field
                          v-model="editedItem.modelo"
                          label="modelo"
                          prepend-icon="subtitles"
                        ></v-text-field>
                      </v-col>
    
    
                       <v-col  cols="12" sm="5" md="3">
                        <v-text-field
                          v-model="editedItem.color"
                          label="color"
                          prepend-icon="palette"
                        ></v-text-field>
                      </v-col>
    
    
    
                       <v-col  cols="12" sm="5" md="3">
                        <v-text-field
                          v-model="editedItem.anio"
                          label="año"
                          prepend-icon="today"
                        ></v-text-field>
                      </v-col>
    
    
                       <v-col  cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.chasis"
                          label="chasis"
                          prepend-icon="confirmation_number"
                        ></v-text-field>
                      </v-col>
    
    
                       <v-col  cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.serie"
                          label="serie"
                          prepend-icon="format_list_numbered"
                        ></v-text-field>
                      </v-col>
    
    
                       <v-col  cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.imei"
                          label="imei"
                          prepend-icon="book_online"
                        ></v-text-field>
                      </v-col>
    
                       <v-col  cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.simcard"
                          label="Simcard"
                          prepend-icon="sim_card"
                        ></v-text-field>
                      </v-col>
    
                        <v-col  cols="12" sm="5" md="3">
                          <v-select
                                v-model="editedItem.proveedor_simcard"
                                :items="items_Proveedores"
                                prepend-icon="support_agent"
                                label="Proveedor"
                            ></v-select>
                      </v-col>
    
                      <v-col  cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.simcard_anterior"
                          label="Simcard Anterior"
                          prepend-icon="sim_card"
                        ></v-text-field>
                      </v-col>
    
    
                      <v-col  cols="12" sm="12" md="12">
                       
                            <v-select
                               class="pt-0"
                               prepend-icon="headphones_battery"
                                v-model="editedItem.id_accesorios_instalados"
                                :items="items_accesorios"
                                attach
                                chips
                                label="Accesorios Instalados"
                                multiple
                              ></v-select>
    
                      </v-col>
    
    
                      <v-col  cols="12" sm="12" md="12">
                            <v-textarea
                                clearable
                                clear-icon="cancel"
                                rows="2"
                                label="Ubicacion Equipo"
                                v-model="editedItem.ubicacion_equipo"
                                prepend-icon="notes"
                              ></v-textarea>
                        </v-col>
    
    
                      <v-col  cols="12" sm="12" md="12">
                              <v-textarea
                                  clearable
                                  clear-icon="cancel"
                                  rows="2"
                                  label="Observacion Tecnico"
                                  v-model="editedItem.observacion_tecnico"
                                  prepend-icon="comment"
                                ></v-textarea>
    
    
                      </v-col>
    
    
                    </v-row>
    
                   </v-container>
                  </v-tab-item> 
    
                  <v-tab-item  v-show="formTitle=='Edicion bitacora'">
    
                    <v-card>
                      <v-card-text>
    
                        <v-row>
    
                           <v-col cols="12" v-if="editedItem.pdf_diagrama">
                            <v-btn class="mt-2" @click="DescargarPdf(editedItem.pdf_diagrama)" >
                              <v-icon color="indigo">download</v-icon>
                              Diagrama<v-icon color="indigo">picture_as_pdf</v-icon></v-btn>
                           </v-col>
    
                            <v-col cols="12">
                              <v-file-input  single-line  show-size label="Pdf - Diagrama"  ref="filehoja1" v-model="filehoja1"  width="90px" @change="status_procesado1=false; finalizadaCarga=false"></v-file-input>
                            </v-col>
    
    
                           <v-col cols="12" v-if="editedItem.pdf_diagrama_orden">
                           <v-btn class="mt-2"  @click="DescargarPdf(editedItem.pdf_diagrama_orden)">
                             <v-icon color="indigo">download</v-icon>
                             Hoja Trabajo<v-icon color="indigo">picture_as_pdf</v-icon></v-btn>
                          </v-col>
    
                            <v-col cols="12">
                              <v-file-input  single-line  show-size label="Pdf - Hoja Trabajo"  ref="filehoja2" v-model="filehoja2"  width="90px" @change="status_procesado2=false; finalizadaCarga=false"></v-file-input>
                          </v-col>
    
                      </v-row>
    
                      </v-card-text>
    
    
                       <v-card-actions class="justify-center">
                            <v-btn class="info" v-show="status_procesado1==false || status_procesado2==false" @click="submitFiles()"><v-icon dark class="mx-1">engineering</v-icon>Subir Archivos</v-btn>
                        </v-card-actions>
                    </v-card>
    
                  </v-tab-item>
    
    
                  </v-tabs>
                  
                </v-card-text>
    
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
    
                  <v-btn color="success" @click="save">
                   Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Seguro deseas deshabilitar este registro?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
    
   
    
    
    
        <v-dialog v-model="dialogInicio" persistent max-width="280">
            <v-card>
              <v-toolbar flat color="red" class="text-center">
              <v-toolbar-title class="white--text  text-center"><strong>Bitacora por fechas</strong></v-toolbar-title>
            </v-toolbar>
    
              <v-card-text>
    
    
    
                <v-flex>
            
                
    
                          <v-menu
                            ref="menuFecha1"
                            v-model="menuFecha1"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                        
                            offset-y
                            min-width="290px"
                          >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="fecha_inicio"
                                    label="Fecha inicio"
                                    prepend-icon="event"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker v-model="fecha_inicio" locale="es"
                                no-title  @input="menuFecha1 = false" scrollable>
                                </v-date-picker>
                          </v-menu>
                    </v-flex>
    
                <v-flex>
                      <v-menu
                        ref="menuFecha2"
                        v-model="menuFecha2"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                    
                        offset-y
                        min-width="290px"
                      >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha_fin"
                                label="Fecha fin"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_fin" locale="es"
                            no-title  @input="menuFecha2 = false" scrollable>
                            </v-date-picker>
                      </v-menu>
    
                    </v-flex>
    
                
                      <v-flex justify-center d-flex>
                        
                            <v-btn color="success" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                          <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>
    
                    </v-flex>
        
              </v-card-text>
            
            </v-card>
        </v-dialog>
    
        
        <v-dialog v-model="dialogDetalleBitacora" persistent width="800">
    
          
    
          <v-card>
    
             <v-toolbar   color="red">
             <v-toolbar-title class="white--text">Detalle Bitacora</v-toolbar-title>
    
             <v-spacer></v-spacer>
             <v-icon @click="dialogDetalleBitacora=false" dark>close</v-icon>
    
            </v-toolbar>
    
    
              
                <v-card-text class="mt-3">
    
                   <v-carousel :continuous="false" :show-arrows="true" class="pb-0"
                  hide-delimiter-background delimiter-icon="crop_7_5" height="500">
    
                   <!-- primer carrusel-->
                
                <v-carousel-item>
    
    
                  <v-row>
    
    
                          <v-col cols="5">
                                <v-list>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">today</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ ConvertirFecha(viewItem.fecha) }}</v-list-item-title>
                                        <v-list-item-subtitle>Fecha hora</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                      <v-divider inset></v-divider>
    
    
                      
    
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">contact_page</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.contacto }}</v-list-item-title>
                                        <v-list-item-subtitle>contacto</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                      <v-divider inset></v-divider>
    
    
                                      <v-list-item class="pt-0" v-if="viewItem.id_tipo_trabajo">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">home_repair_service</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.id_tipo_trabajo.nombre_trabajo }}</v-list-item-title>
                                        <v-list-item-subtitle>Tipo trabajo</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                      <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">label</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.status }}</v-list-item-title>
                                        <v-list-item-subtitle>Estatus</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                      <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">comment</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                            <span class="text--caption">{{ viewItem.observacion_operador }}</span>
                                        <v-list-item-subtitle>Observacion Operador</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                        <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">local_offer</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.etiqueta }}</v-list-item-title>
                                        <v-list-item-subtitle>Etiqueta</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                       <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">shutter_speed</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title> <strong>{{ viewItem.hora_llegada_tecnico }} </strong>  </v-list-item-title>
                                        <v-list-item-subtitle>llegada Tecnico</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
                                </v-list>
                            </v-col>
    
                              <v-col cols="6">
                                <v-list>
    
                                    
                                      <v-list-item class="pt-0" v-if="viewItem.id_bitacora_cliente">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">account_circle</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.id_bitacora_cliente.nombre_cliente }}</v-list-item-title>
                                        <v-list-item-subtitle>Nombre cliente</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                    
                                      <v-divider inset></v-divider>
    
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">apartment</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <span class="text--caption">{{ viewItem.direccion }}</span>
                                        <v-list-item-subtitle>Direccion</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                      <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">description</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                            <span class="text--caption">{{ viewItem.descripcion_trabajo }}</span>
                                        <v-list-item-subtitle>Descripcion Trabajo</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
    
                                      <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0" >
                                        <v-list-item-icon>
                                            <v-icon color="indigo">timer</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title> inicio: <strong>{{ viewItem.hora_inicio }} </strong>  fin:  <strong> {{ viewItem.hora_fin }} </strong></v-list-item-title>
                                        <v-list-item-subtitle>Horas realizado</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
                                      <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0" >
                                        <v-list-item-icon>
                                            <v-icon color="indigo">manage_accounts</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>
                                          
                                          <div v-for="(item, index) in viewItem.id_tecnicos"  :key="index">
                                            <span> {{ renderizarTecnico(item.ref_tecnico) }}  /</span>
                                          </div>
                                          
                                          
                                          </v-list-item-title>
                                        <v-list-item-subtitle>Tecnico</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                      <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0" >
                                        <v-list-item-icon>
                                            <v-icon color="indigo">edit</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                          <span class="text--caption">{{ viewItem.editado }}</span>
                                        <v-list-item-subtitle>Editado</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                         <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">arrow_circle_down</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                       
                                            <v-btn v-if="viewItem.pdf_diagrama"  x-small @click="DescargarPdf(viewItem.pdf_diagrama)" >Diagrama</v-btn>
                                            <v-btn v-if="viewItem.pdf_diagrama_orden" class="mt-2" x-small @click="DescargarPdf(viewItem.pdf_diagrama_orden)">Hoja trabajo</v-btn>
                          
                                       
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
    
                                </v-list>
                            </v-col>
    
                  </v-row>
    
                 </v-carousel-item>
    
    
                <!-- segundo carrusel-->
    
    
                   <v-carousel-item>  
                      <v-row>
    
                          <v-col cols="4" class="pl-2">
                                <v-list>
    
                                      <v-list-item class="pt-0" v-if="viewItem.id_tipo_equipo">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">beenhere</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.id_tipo_equipo.nombre_equipo }}</v-list-item-title>
                                        <v-list-item-subtitle>Tipo equipo</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                      <v-divider inset></v-divider>
    
    
    
                                       <v-divider inset></v-divider>
    
    
                                       <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">closed_caption</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.placa }}</v-list-item-title>
                                        <v-list-item-subtitle>Placa</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
                                      <v-divider inset v-show="viewItem.imei_anterior!=''"></v-divider>
    
                                       <v-list-item class="pt-0" v-show="viewItem.imei_anterior!=''">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">undo</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.imei_anterior }}</v-list-item-title>
                                        <v-list-item-subtitle>Imei anterior</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
    
                      
                                      <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">branding_watermark</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.marca }}</v-list-item-title>
                                        <v-list-item-subtitle>Marca</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                    
                                      <v-divider inset></v-divider>
    
    
                                        <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">today</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.anio }}</v-list-item-title>
                                        <v-list-item-subtitle>Año</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                      
                                    
    
                                     
    
    
                                     
    
    
                                        <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">book_online</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.imei }}</v-list-item-title>
                                        <v-list-item-subtitle>Imei</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
                                        <v-divider inset></v-divider>
    
                                         <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">support_agent</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.proveedor_simcard }}</v-list-item-title>
                                        <v-list-item-subtitle>Proveedor</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
                                      
    
                                        <v-divider inset></v-divider>
    
                                      
    
    
    
                                </v-list>
                          </v-col>
    
    
                          <v-col cols="4">
    
                             <v-list>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">tag</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.id_avl }}</v-list-item-title>
                                        <v-list-item-subtitle>Id Avl</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                    <v-divider inset v-show="viewItem.placa_anterior!=''"></v-divider>
    
                                      <v-list-item class="pt-0" v-show="viewItem.placa_anterior!=''">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">backspace</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.placa_anterior }}</v-list-item-title>
                                        <v-list-item-subtitle>Placa anterior</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
    
                                    <v-divider inset></v-divider>
    
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">commute</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.flota }}</v-list-item-title>
                                        <v-list-item-subtitle>Flota</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                     
                                        <!-- aqui viene-->
                                    
    
                                      <v-divider inset></v-divider>
    
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">subtitles</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.modelo }}</v-list-item-title>
                                        <v-list-item-subtitle>Modelo</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
    
                                        <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">confirmation_number</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                            <span class="text--caption">{{ viewItem.chasis }}</span>
                                        <v-list-item-subtitle>Chasis</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                     
    
                                     
    
                                      <v-divider inset></v-divider>
    
                                      
    
                                         <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">sim_card</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.simcard }}</v-list-item-title>
                                        <v-list-item-subtitle>Simcard</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                      <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">notes</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                          <span class="text--caption">{{ viewItem.ubicacion_equipo }}</span>
                                        <v-list-item-subtitle>Ubicacion equipo</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                      <v-divider inset></v-divider>
    
                                      
                                       
    
    
                                      
    
    
                                      
    
    
                             </v-list>       
    
    
    
                          </v-col>
    
                            <v-col cols="4">
    
                                     <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">label_important</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.nombre }}</v-list-item-title>
                                        <v-list-item-subtitle>Nombre</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
    
                                       <v-divider inset v-show="viewItem.serie_anterior!=''"></v-divider>
                                      
                                       <v-list-item class="pt-0" v-show="viewItem.serie_anterior!=''">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">fast_rewind</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.serie_anterior }}</v-list-item-title>
                                        <v-list-item-subtitle>Serie anterior</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
    
                                      <v-divider inset></v-divider>
    
                                       <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">directions_car_filled</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.estilo }}</v-list-item-title>
                                        <v-list-item-subtitle>Estilo</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                       
                                    <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">palette</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                           <v-list-item-title>{{ viewItem.color }}</v-list-item-title>
                                        <v-list-item-subtitle>Color</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                       <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">format_list_numbered</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.serie }}</v-list-item-title>
                                        <v-list-item-subtitle>Serie</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                        <v-divider inset></v-divider>
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">sim_card</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ viewItem.simcard_anterior }}</v-list-item-title>
                                        <v-list-item-subtitle>simcard Ant.</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
                                       <v-divider inset></v-divider>
    
                                         <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">comment</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                           <span class="text--caption">{{ viewItem.observacion_tecnico }}</span>
                                        <v-list-item-subtitle>Observacion Tecnico</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
                                      <v-divider inset></v-divider>
    
                                     
    
    
                                      
    
                                        
    
    
                                     
    
    
                                     
    
                            </v-col>
                       
                    </v-row>
                  </v-carousel-item>
    
    
    
                  <!-- tercer carrusel --->
                   <v-carousel-item>
    
                      <v-row>
    
                          <v-col cols="4" class="pl-2">
                                <v-list>
    
    
                                      <v-list-item class="pt-0">
                                        <v-list-item-icon>
                                            <v-icon color="indigo">headphones_battery</v-icon>
                                          </v-list-item-icon>
                                        <v-list-item-content>
                                        <v-list-item-title>
                                       
    
                                           <div v-for="(item, index) in viewItem.id_accesorios_instalados"  :key="index">
                                            <span class="text--caption"> {{ renderizarAccesorio(item.ref_accesorio) }}  /</span>
                                          </div>
                                          
                                          </v-list-item-title>
                                        <v-list-item-subtitle>Accesorios instalados</v-list-item-subtitle>
                                      </v-list-item-content>
                                      </v-list-item>
    
    
    
                                   
                                </v-list>
                          </v-col>
                      </v-row>
    
    
                   </v-carousel-item>
    
    
             </v-carousel>
    
    
                </v-card-text>
               
              </v-card>
        </v-dialog>
    
    
    
         <v-dialog v-model="dialogNuevoContacto" max-width="500px">
    
                <v-card>
                <v-toolbar color="red">
                <v-toolbar-title class="white--text">Nuevo Contacto </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon dark @click="dialogNuevoContacto=false">close</v-icon>
              </v-toolbar>
    
              <v-card-text>
    
                <v-row>
    
                  <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedContacto.nombre_contacto"
                          prepend-icon="face"
                          label="Nombre contacto"
                        ></v-text-field>
                      </v-col>
    
    
                        <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedContacto.telefonos"
                          prepend-icon="phone"
                          label="Telefonos"
                        ></v-text-field>
                      </v-col>
    
                       <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedContacto.correos"
                          prepend-icon="email"
                          label="Correos"
                        ></v-text-field>
                      </v-col>
    
    
                       <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedContacto.observacion"
                          prepend-icon="comment"
                          label="observacion"
                        ></v-text-field>
                      </v-col>
    
                </v-row>
    
              </v-card-text>
    
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="GuardarContactos()">Guardar</v-btn>
    
                <v-btn text color="primary">cancelar</v-btn>
              </v-card-actions>
                </v-card>
    
    
          </v-dialog>
    
    
           <v-dialog v-model="dialogNuevaDireccion" max-width="500px">
    
                <v-card>
                <v-toolbar color="red">
                <v-toolbar-title class="white--text">Nueva Direccion</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-icon dark @click="dialogNuevaDireccion=false">close</v-icon>
              </v-toolbar>
    
              <v-card-text>
    
                <v-row>
    
                     <v-col cols="12" sm="12" md="12">
    
                      <v-select
                        v-model="editedDireccion.tipo"
                        :items="itemsTipoDireccion"
                        :menu-props="{ top: true, offsetY: true }"
                        prepend-icon="label"
                        label="Tipo Direccion"
                      ></v-select>
    
                      </v-col>
    
    
                    
    
    
                        <v-col cols="12" sm="12" md="12">
                       <v-textarea
                          auto-grow rows="2" outlined
                          v-model="editedDireccion.texto"
                          label="Texto direccion"
                        ></v-textarea>
                      </v-col>
    
    
                         <v-col cols="12" sm="5" md="5">
                        <v-text-field
                          v-model="editedDireccion.latitud"
                          prepend-icon="gps_fixed"
                          label="latitud"
                         
                        ></v-text-field>
                      </v-col>
    
    
                       <v-col cols="12" sm="5" md="5">
                        <v-text-field
                          v-model="editedDireccion.longitud"
                          prepend-icon="gps_fixed"
                          label="longitud"
                         
                        ></v-text-field>
                      </v-col>
    
    
                      <v-col v-if="editedDireccion.longitud!=''  && editedDireccion.latitud!='' "  cols="2" class="mt-3">
                         <v-btn fab dark color="primary" small @click="CrearMarcador()"><v-icon>place</v-icon></v-btn> 
                      </v-col>
    
                      <v-col cols="12" sm="12" md="12">
                         <div style="height:425px;" class="pa-0">
                           <l-map
                             :key="componentKey"
                              ref="map"
                              :zoom="zoom"
                              :center="center"
                              :options="mapOptions"
                              @click="onMapClick"
                              style="height: 100%;z-index:50;"
                        >
    
                        <l-tile-layer
                        :url="urlOpen"
                        :attribution="attributionOpen"
                        />
    
                         <LMarker v-if="point.lat" :lat-lng="point"></LMarker>
                        </l-map>
                         </div>
                      </v-col>
    
                </v-row>
    
              </v-card-text>
    
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="GuardarDireccion()">Guardar</v-btn>
    
                <v-btn text color="primary" @click="dialogNuevaDireccion=false">cancelar</v-btn>
              </v-card-actions>
                </v-card>
    
    
              </v-dialog>
    
    
              <v-dialog v-model="dialogHistorialBitacora" max-width="1080px">
                <v-card>
                  <v-toolbar color="red">
                  <v-toolbar-title class="white--text">Historial Bitacoras equipo {{ itemHistorial.id_avl }} - {{ itemHistorial.placa}}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon dark @click="dialogHistorialBitacora=false">close</v-icon>
                </v-toolbar>
    
                  <v-card-text>
    
                       <v-data-table
                        :headers="headersHistorial"
                        :items="itemsHistorialBitacora"
                        sort-by="calories"
                        class="elevation-1 mt-5"
                      >
    
                         <template v-slot:[`item.fecha`]="{ item }"> 
                          <span>{{ new Date(item.fecha).toISOString().substr(0, 10) }} </span>
                        </template>
    
    
                          <template v-slot:[`item.id_bitacora_cliente`]="{ item }"> 
                          <span>{{item.id_bitacora_cliente.nombre_cliente}}</span>
                        </template>
    
                          <template v-slot:[`item.id_tipo_trabajo`]="{ item }"> 
                          <span>{{item.id_tipo_trabajo.nombre_trabajo}}</span>
                           <v-icon color="indigo" @click="VerDetalleBitacora(item)">preview</v-icon>
                          </template>
    
                          
    
                           <template v-slot:[`item.observacion_operador`]="{ item }">
                            <span class="text-caption">{{item.observacion_operador}}</span>
                        </template>
    
    
                    
                      </v-data-table>
    
    
                  </v-card-text>
              </v-card>
    
              </v-dialog>
    
    
    
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small  class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small  @click="deleteItem(item)">delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Reset
          </v-btn>
        </template>
    
    
        <template v-slot:[`item.fecha`]="{ item }"> 
           <span>{{ new Date(item.fecha).toISOString().substr(0, 10) }} </span>
         </template>
    
    
          <template v-slot:[`item.id_bitacora_cliente`]="{ item }"> 
           <span>{{item.id_bitacora_cliente.nombre_cliente}}</span>
         </template>
    
         <template v-slot:[`item.id_tipo_trabajo`]="{ item }"> 
           <span>{{item.id_tipo_trabajo.nombre_trabajo}}</span>
            <v-icon color="indigo" @click="VerDetalleBitacora(item)">preview</v-icon>
           <v-icon v-show="item.procesado_avl==true" color="indigo">task_alt</v-icon>
           <v-icon v-show="item.procesado_avl==false &&  item.status=='Realizado'" color="red">error</v-icon>
    
         </template>
    
          <template v-slot:[`item.id_tipo_equipo`]="{ item }"> 
           <span>{{item.id_tipo_equipo.nombre_equipo}}</span>
         </template>
    
    
        <template v-slot:[`item.status`]="{ item }"> 
           <span class="text-caption">{{item.status}}</span>
          
            <v-icon color="indigo" @click="DescargarBitacora(item)">file_download</v-icon>
             <v-icon color="indigo" @click="HistorialBitacora(item)">work_history</v-icon>
         </template>
    
    
    
       <template v-slot:[`item.direccion`]="{ item }">
          <span class="text-caption">{{item.direccion}}</span>
            <v-icon color="indigo" @click="VerMapa(item)">map</v-icon>
       </template>
    
    
        <template v-slot:[`item.observacion_operador`]="{ item }">
          <span class="text-caption">{{item.observacion_operador}}</span>
       </template>
    
    
       <template v-slot:[`item.tecnicos`]="{ item }">
          <span class="text-caption">{{item.tecnicos}}</span>
       </template>
    
        
    
    
    
      
    
       
    
      </v-data-table>
     
       </v-flex>
    
      <v-flex xs12 sm12 md12 lg12>
       <span class="d-block">
      <v-icon color="red">error</v-icon>
      Bitacoras pendientes de actualizar en AVL</span>
      </v-flex>
    </v-container>
    </template>
    
    
    
    <script>
    
    import axios from 'axios';
    import { mapMutations,mapState } from "vuex";
    

    
    //uso de mapa en direccion de bitacora
    
    import 'leaflet/dist/leaflet.css';
    import { latLng,Icon} from "leaflet";
    import { LMap, LTileLayer,LMarker } from "vue2-leaflet";
    
    delete Icon.Default.prototype._getIconUrl;
    
    Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });
    
    
    import moment from 'moment';
    
    //import { FileSaver,saveAs } from 'file-saver';
    
      export default {
        components:{
         
          LMap,LTileLayer,LMarker
        },
        data: () => ({
    
          ArrayClientes:[],
          items_cliente:[],
    
          items_TiposTrabajos:[],
          arrayTiposTrabajos:[],
    
          items_status:[  {'value':'Agendado', 'text':'Agendado'},
                          {'value':'Procesando', 'text':'Procesando'},
                          {'value':'Cancelado', 'text':'Cancelado'},
                          {'value':'Realizado', 'text':'Realizado'}
                          ],
    
          ArrayTenicos:[],
          items_tecnicos:[], 
    
          items_contacto:[],
          items_direcciones:[],
    
          items_etiquetas:[
                          {'value':'Equipo_Manipulado', 'text':'Equipo_Manipulado'},
                          {'value':'Equipo_Inhibido', 'text':'Equipo_Inhibido'},
                          {'value':'Error_simcard', 'text':'Error_simcard'},
                          {'value':'Flojedad_Arnes', 'text':'Flojedad_Arnes'},
                          {'value':'Error_instalacion', 'text':'Error_instalacion'},
    
                          {'value':'Vehiculo_bateriaBaja', 'text':'Vehiculo_bateriaBaja'},
                          {'value':'Vehiculo_SinBateria', 'text':'Vehiculo_SinBateria'},
                          {'value':'Fusible_Quemado', 'text':'Fusible_Quemado'},
                          {'value':'Rele_Dañado', 'text':'Rele_Dañado'},
    
    
                           {'value':'Equipo_Mojado', 'text':'Equipo_Mojado'},
                           {'value':'Otro', 'text':'Otro'}
          ],   
          
          
          ArrayTiposEquipos:[],
          items_TiposEquipos:[],
    
           items_Proveedores:[
             
                        {'value':'Tigo', 'text':'Tigo'},
                        {'value':'Claro', 'text':'Claro'}
           ],
    
    
           ArrayAccesorios:[],
           items_accesorios:[],
    
           id_avl_buscar:'',
    
    
    
          dialog: false,
          dialogDelete: false,
          headers: [
              { text: 'Acciones', value: 'actions', sortable: false },
              { text: 'Fecha', value: 'fecha' },
              { text: 'Hora Establecida', value: 'hora_establecida' },
    
              { text: 'Cliente', value: 'id_bitacora_cliente' },
              { text: 'Tipo Trabajo', value: 'id_tipo_trabajo' },
              { text: 'Estatus', value: 'status' },
              { text: 'Id Avl', value: 'id_avl' },
              { text: 'Placa', value: 'placa' },
              { text: 'Direccion', value: 'direccion' },
              { text: 'Observacion Operador', value: 'observacion_operador' }
           
    
           
          ],
          itemsBitacora:[],
          search:'',
    
           data_excel:[],
        column_excel:[ 
                {label: "fecha",  field: "fecha"},
                {label: "hora_establecida",  field: "hora_establecida"},
                {label: "hora_llegada_tecnico", field: "hora_llegada_tecnico"},
                {label: "hora_inicio", field: "hora_inicio"},
                {label: "hora_fin", field: "hora_fin"},
                {label: "tiempo_espera", field:"tiempo_espera"},
    
                {label: "cliente", field: "cliente"},                                     //id
                {label: "contacto", field:"contacto"},
    
                {label: "direccion",  field: "direccion"},
    
                {label: "Tipo_trabajo",  field: "tipo_trabajo"},                      //id
                {label: "descripcion_trabajo",  field: "descripcion_trabajo"},
    
                {label: "status", field: "status"},
                {label: "etiqueta", field: "etiqueta"},
    
    
                {label: "placa_anterior", field: "placa_anterior"},
                {label: "serie_anterior", field: "serie_anterior"},
                {label: "imei_anterior", field: "imei_anterior"},
                
    
                {label: "Tipo_Equipo", field: "tipo_equipo"},                         //id
                {label: "id_avl", field: "id_avl"},
    
                {label: "nombre", field: "nombre"},
                {label: "placa", field: "placa"},
                {label: "flota", field: "flota"},
    
                {label: "Tecnicos", field: "tecnicos"},                                //id
                {label: "observacion_operador", field: "observacion_operador"},
    
                {label: "estilo", field: "estilo"},
                {label: "marca", field: "marca"},
                {label: "modelo", field: "modelo"},
                {label: "color", field: "color"},
                {label: "anio", field: "anio"},
                {label: "chasis", field: "chasis"},
                {label: "serie", field: "serie"},
                {label: "imei", field: "imei"},
                {label: "simcard_anterior", field: "simcard_anterior"},
                {label: "simcard", field: "simcard"},
                {label: "proveedor_simcard", field: "proveedor_simcard"},
    
                {label: "accesorios_instalados", field: "accesorios_instalados"},          //id 
                {label: "ubicacion_equipo", field: "ubicacion_equipo"},
                {label: "observacion_tecnico", field: "observacion_tecnico"},
                {label: "procesado_avl", field: "procesado_avl"},
                {label: "creado", field: "creado"},
                {label: "editado", field: "editado"}
    
    
                ],
    
    
            //opciones del dialogo de fechas
            menu: false,
            dialogInicio:false,
            menuFecha1:false,
            fecha_inicio:new Date().toISOString().substr(0, 10),
            menuFecha2:false,
            fecha_fin:new Date().toISOString().substr(0, 10),
            date: new Date().toISOString().substr(0, 10),
    
            menuFecha_bitacora:false,
            date_bitacora: new Date().toISOString().substr(0, 10),
    
    
            ver_campos_traslado:false, //al cambiar equipo necesitaremos placa anterior 
    
            ver_campos_cambio:false, //al cambiar equipo necesitaremos serie-imei anterior
    
           
            time: null,
            menu_hora_esta:false,
            menu_hora_inicio:false,
            menu_hora_fin:false,
            menu_hora_llegada:false,
    
    
    
        
          editedIndex: -1,
          editedItem: {
            fecha: new Date().toISOString().substr(0, 10),
            hora_establecida: '',
            hora_inicio: '',
            hora_fin: '',
            tiempo_espera:0,
            hora_llegada_tecnico:'',
            id_bitacora_cliente: '', //id
            direccion:'',
    
            id_tipo_trabajo:'', //id
            descripcion_trabajo:'',
    
            status:'',  //Realizado, Cancelado, Agendado
            etiqueta:'', //flojedad conexion, simcard dañado, equipo freezado, manipulacion equipo, mala instalacion
    
            id_tipo_equipo:'', //id
    
            //campos habilitados solo para cambio de equipo o traslado
            placa_anterior:'',
            serie_anterior:'',
            imei_anterior:'',
        
    
            id_avl:'',
            nombre:'',
            placa :'',
            flota :'',
    
            id_tecnicos:'',  //id
            observacion_operador:'',
    
            estilo:'',
            marca: '',
            modelo:'',
            color:'',
            anio:'',
            chasis:'',
            serie:'',
            imei:'',
            simcard:'',
            simcard_anterior:'',
            proveedor_simcard:'',
    
            id_accesorios_instalados:'',
            ubicacion_equipo:'',
            observacion_tecnico:'',
    
            activo:1,
            procesado_avl:false,  //aun nadie hizo cambios en AVL de esta bitacora
            creado:'',
            editado:''
    
          },
          defaultItem: {
            fecha: new Date().toISOString().substr(0, 10),
            hora_establecida: '',
            hora_inicio: '',
            hora_fin: '',
            tiempo_espera:0,
            hora_llegada_tecnico:'',
            id_bitacora_cliente: '', //id
            direccion:'',
    
            id_tipo_trabajo:'', //id
            descripcion_trabajo:'',
    
            status:'',  //Realizado, Cancelado, Agendado
            etiqueta:'', //flojedad conexion, simcard dañado, equipo freezado, manipulacion equipo, mala instalacion
    
            id_tipo_equipo:'', //id
    
             //campos habilitados solo para cambio de equipo o traslado
            placa_anterior:'',
            serie_anterior:'',
            imei_anterior:'',
    
            id_avl:'',
            nombre:'',
            placa :'',
            flota :'',
    
            id_tecnicos:'',  //id
            observacion_operador:'',
    
            estilo:'',
            marca: '',
            modelo:'',
            color:'',
            anio:'',
            chasis:'',
            serie:'',
            imei:'',
            simcard:'',
            simcard_anterior:'',
            proveedor_simcard:'',
    
            id_accesorios_instalados:'',
            ubicacion_equipo:'',
            observacion_tecnico:'',
    
            activo:1,
            procesado_avl:false, //aun nadie hizo cambios en AVL de esta bitacora
            creado:'',
            editado:''
    
          },
    
    
           errorMessages:'',
           rules: {
              numerico:(value)=>value > 0 || "valor incorrecto",
              min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
              required: (value) => !!value || "campo Requerido.",
              email: (value) => {
                                  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                  return pattern.test(value) || "Invalid e-mail.";
                               }
    
           },
    
           switchFiltro:false,
           LabelSwitch:'Todos',
           ArregloGlobalBitacoras:[],  //debido a filtro de pendientes de actulizar o no 
    
           dialogDetalleBitacora:false,
           
           viewItem:{},
    
           //nuevo contacto que no existe y usuario desee añadir uno nuevo
    
    
            dialogNuevoContacto:false,
    
            editedContacto:{
                nombre_contacto:'',
                telefonos:'',
                correos:'',
                observacion:'',
            },
    
            itemsContact:[],  //contactos de  cliente, qui se almacenara uno nuevo para ser enviado al backend
    
    
    
          //nueva direccion si usuario desea añadir una nueva
    
           itemsDirecciones:[],  //direcciones del cleinte seleccionado
    
              dialogNuevaDireccion:false,
              editedDireccion:{
                    tipo:'',
                    texto:'',
                    latitud:'',
                    longitud:'',
                  },
    
              itemsTipoDireccion: ['Oficina', 'Casa', 'Plantel', 'Otro'],
    
                 //datos de mapa
              componentKey: 0,
              zoom: 11,
              center: latLng(12.0818212,-86.2672801),
              url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
              attribution:'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
              currentZoom: 11.5,
              currentCenter: latLng(12.0818212,-86.2672801),
              showParagraph: false,
              mapOptions: {
                zoomSnap: 0.5
              },
    
              //varibales de mapas
              urlOpen:'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
              attributionOpen:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    
              point:{},
    
                itemsHistorialBitacora:[],
              headersHistorial:[
                  { text: 'Fecha', value: 'fecha' },
                  { text: 'Hora Establecida', value: 'hora_establecida' },
    
                  { text: 'Cliente', value: 'id_bitacora_cliente' },
                  { text: 'Tipo Trabajo', value: 'id_tipo_trabajo' },
                  { text: 'Estatus', value: 'status' },
                  { text: 'Id Avl', value: 'id_avl' },
                  { text: 'Placa', value: 'placa' },
                  { text: 'Direccion', value: 'direccion' },
                  { text: 'Observacion Operador', value: 'observacion_operador' },
              ],
    
    
              dialogHistorialBitacora:false,
              itemHistorial:{
                id_avl:'',
                placa:''
              },
    
    
              filehoja1:null,
              filehoja2:null,
    
              status_procesado1:true,
              status_procesado2:true,
    
    
    
    
    
        }),
    
        computed: {
    
            ...mapState(['usuario', 'token']),
    
          formTitle () {
            return this.editedIndex === -1 ? 'Nuevo registro bitacora' : 'Edicion bitacora'
          },
        },
    
        watch: {
          dialog (val) {
            val || this.close()
          },
          dialogDelete (val) {
            val || this.closeDelete()
          },
    
           switchFiltro(val){
              if(val==true){
                 this.LabelSwitch='Pend.Actualizar';
                 this.FiltrarTablaBitacora();
              }else{
                   this.LabelSwitch='Todos';
                    this.FiltrarTablaBitacora();
              }
          },
    
          dialogNuevaDireccion(val){
    
                    if(val){
                      this.reloadMap();
                  }else{
                      this.editedDireccion={};
                      this.point={};
                  }
              },
    
    
        },
    
        created () {

    
          //por defecto datos del di actual
           this.fecha_inicio=this.CalcularFechaHoy();
           this.fecha_fin=this.CalcularFechaHoy();
    
           this.defaultItem.fecha=this.CalcularFechaHoy();
           this.editedItem.fecha=this.CalcularFechaHoy();
    
           this.listarTecnicos();

        
        
        },
    
        methods: {
    
             ...mapMutations(['mostrarLoading','ocultarLoading']),
         
          initialize () {
            this.itemsBitacora = [];
          },
    
          listarClientes(){
    
    
             let config={headers:{token:this.token}};
             this.mostrarLoading({titulo:'Accediendo a datos'});
    
             let me=this;
    
              me.items_cliente=[];
              me.ArrayClientes=[];
    
              axios.get('clientes-all', config
                        ).then(function(response){
                          console.log(response);
                          me.ArrayClientes=response.data;
    
                           me.ArrayClientes.map(function(x){
                                me.items_cliente.push({'value':x._id, 'text':x.nombre_cliente});
                           });
    
                           me.actualizarContactos();
    
                           me.ocultarLoading();
                        }).catch(function(error){
                          console.log(error);
                        });
    
    
    
    
          },
    
          actualizarContactos(){
    
           this.items_contacto=[];
           this.items_direcciones=[];
           this.itemsContact=[]; //almacena para enviar todos los contactos al backend
           let me=this;
    
        
    
            me.ArrayClientes.map(function(x){
    
                if(me.editedItem.id_bitacora_cliente==x._id){
    
                  let contactos=x.contactos;
                  me.itemsContact=x.contactos;
    
                  let direcciones=x.direcciones;
                  me.itemsDirecciones=x.direcciones
    
                   console.log(me.itemsDirecciones);
    
                
    
                  contactos.map(function(z){
                      me.items_contacto.push(z.nombre_contacto+'-'+z.telefonos);
                  });
    
                  direcciones.map(function(z){
    
                    if(z.texto){
                       me.items_direcciones.push(z.texto);
                     }
                   
    
                  })
    
                }
    
            });
    
           
    
          },
    
          listarTiposTrabajos(){
    
              let config={headers:{token:this.token}};
              this.mostrarLoading({titulo:'Accediendo a datos'});
    
              let me=this;
              this.items_TiposTrabajos=[];
              this.arrayTiposTrabajos=[];
    
             axios.get('trabajos', config
                        ).then(function(response){
                          console.log(response);
                          me.arrayTiposTrabajos=response.data;
    
                             me.arrayTiposTrabajos.map(function(x){
                                            me.items_TiposTrabajos.push({'text':x.nombre_trabajo, 'value':x._id});
                                });
    
                     
    
                      me.ocultarLoading();
                        }).catch(function(error){
                          console.log(error);
                        });
    
    
    
          },
    
    
          listarTecnicos(){
    
              let config={headers:{token:this.token}};
              this.mostrarLoading({titulo:'Accediendo a datos'});
    
              let me=this;
    
            this.ArrayTenicos=[];
            this.items_tecnicos=[];  
    
              axios.get('tecnicos', config
                        ).then(function(response){
                          console.log(response);
                          me.ArrayTenicos=response.data;
    
                             me.ArrayTenicos.map(function(x){
                                            me.items_tecnicos.push({'text':x.nombre_tecnico, 'value':x._id});
                                });

                             me.listar();
                              // me.listarClientes();
    
                            /// me.listarEquipos();
    
                             me.ocultarLoading();
                        }).catch(function(error){
                          console.log(error);
                        });
    
    
    
    
          },
    
          listarEquipos(){
    
    
              let config={headers:{token:this.token}};
              this.mostrarLoading({titulo:'Accediendo a datos'});
    
              let me=this;
    
            this.ArrayTiposEquipos=[];
            this.items_TiposEquipos=[];
    
    
              axios.get('equipos', config
                        ).then(function(response){
                          console.log(response);
                          me.ArrayTiposEquipos=response.data;
    
                             me.ArrayTiposEquipos.map(function(x){
                                            me.items_TiposEquipos.push({'text':x.nombre_equipo, 'value':x._id});
                                });
    
                             me.listarAccesorios();
                             me.ocultarLoading();
                        }).catch(function(error){
                          console.log(error);
                        });
    
    
    
          },
    
          listarAccesorios(){
    
              let config={headers:{token:this.token}};
              this.mostrarLoading({titulo:'Accediendo a datos'});
    
              let me=this;
    
    
             this.ArrayAccesorios=[];
             this.items_accesorios=[];
    
             this.data_excel=[];
    
                 axios.get('accesorios', config
                        ).then(function(response){
                          console.log(response);
                          me.ArrayAccesorios=response.data;
    
                             me.ArrayAccesorios.map(function(x){
                                            me.items_accesorios.push({'text':x.nombre_accesorio, 'value':x._id});
                                });
    
                                //llenamos el exportador a excel de la tabla
    
                                 me.ArregloGlobalBitacoras.map(function(x){
    
                                  let insert_excel=x;
                                      insert_excel.fecha= new Date(x.fecha).toISOString().substr(0, 10);
                                      insert_excel.cliente=x.id_bitacora_cliente.nombre_cliente;
                                      insert_excel.tipo_trabajo=x.id_tipo_trabajo.nombre_trabajo;
    
    
                                      if(x.id_tipo_equipo){
                                          insert_excel.tipo_equipo=x.id_tipo_equipo.nombre_equipo;
                                      }
    
                                      let texto_tecnicos='';
    
                                      x.id_tecnicos.map(function(y){
                                        texto_tecnicos=texto_tecnicos+''+ me.renderizarTecnico(y.ref_tecnico)+', ';
                                      });
    
                                      insert_excel.tecnicos=texto_tecnicos;
    
                                      let texto_accesorio='';
    
                                      let arr_acc=x.id_accesorios_instalados;
    
                                  
                                      if((x.id_accesorios_instalados) &&( arr_acc.length >0)){
    
                                       arr_acc.map(function(z){
                                          texto_accesorio=texto_accesorio+''+ me.renderizarAccesorio(z.ref_accesorio)+',';
                                       });
    
    
                                     }
                                    
                                      
    
                                      insert_excel.accesorios_instalados=texto_accesorio;
    
                                   
    
    
                                  me.data_excel.push(insert_excel);
                                });
                
                             me.ocultarLoading();
                        }).catch(function(error){
                          console.log(error);
                        });
    
    
          },
    
          HabilitarcamposTrabajo(){
    
            this.ver_campos_traslado=false;
            this.ver_campos_cambio=false;
            let me=this;
    
    
            this.items_TiposTrabajos.map(function(x){
    
                if(x.value==me.editedItem.id_tipo_trabajo){
    
                  let Evento_hacer=x.text;
                    
                    //Traslado de equipo
    
                      let StringTraslado='Traslado';
                      let posicionTraslado = Evento_hacer.indexOf(StringTraslado);
                      if (posicionTraslado !== -1){
                          console.log("Traslado equipo detectado: " + posicionTraslado+':'+Evento_hacer);
                          me.ver_campos_traslado=true;
                      }
    
    
                    //Cambio de equipo
    
                      let StringCambio='Cambio';
                      let posicionCambio = Evento_hacer.indexOf(StringCambio);
                      if (posicionCambio !== -1){
                          console.log("Cambio equipo detectado: " + posicionCambio+':'+Evento_hacer);
                          me.ver_campos_cambio=true;
                      }
    
    
    
                }
    
            });
                 
          },
    
    
          listar(){
    
    
            let config={headers:{token:this.token}};
             this.mostrarLoading({titulo:'Accediendo a datos'});
    
             let me=this;
    
              me.itemsBitacora=[];
              me.ArregloGlobalBitacoras=[];
              this.data_excel=[];
              axios.post('bitacoras',
                                   { 
                                    'desde':new Date(this.fecha_inicio).toISOString().substr(0, 10),
                                    'hasta':new Date(this.fecha_fin).toISOString().substr(0, 10) 
                                  },
                 config
              ).then(function(response){

                console.log(response);

                let respuesta=response.data;

                respuesta.map(function(x){

                  if(x.id_tecnicos){ 

                    if(x.id_tecnicos.length >0 ){ 


                        let tecnicos_asociados=x.id_tecnicos;


                        tecnicos_asociados.map(function(y){


                          me.ArrayTenicos.map(function(z){

                               y.nombre_tecnico='';

                                    if(z._id.toString()==y.ref_tecnico){
                                           y.nombre_tecnico=z.nombre_tecnico;
                                         console.log('igual bingo--'+y.nombre_tecnico);

                                          if(y.nombre_tecnico==me.usuario.nombre_ejecutivo){

                                                me.itemsBitacora.push(x);
                                                me.ArregloGlobalBitacoras.push(x);
                                              }
                                        }
                                 
                                });

                              


                            

                          });


                            
                   

                    }

                                   
                   }


                 });


              

              
    
                me.listarTiposTrabajos();
    
                me.ocultarLoading();
                me.dialogInicio=false;
              }).catch(function(error){
                console.log(error);
              });
    
    
          },
    
         FiltrarTablaBitacora(){
    
            let me=this;
             me.itemsBitacora=[];
    
               if(me.switchFiltro==true){  //filtro por pendientes a actualizar
    
                     me.ArregloGlobalBitacoras.map(function(x){
                          if((x.procesado_avl==false) && (x.status=='Realizado')){
                              me.itemsBitacora.push(x);
                          }
                     });
    
               }else{ //todos los registros
                  me.itemsBitacora=me.ArregloGlobalBitacoras;
    
               }
    
          },
    
          editItem (item) {
            this.editedIndex = this.itemsBitacora.indexOf(item)
            this.editedItem = Object.assign({}, item);
    
    
            //convertimos las fecha
             this.editedItem.fecha=new Date(item.fecha).toISOString().substr(0, 10);
    
            ///establecemos cliente
             this.editedItem.id_bitacora_cliente=item.id_bitacora_cliente._id;
    
             this.actualizarContactos();
    
            //establecemos tipo trabajo
            this.editedItem.id_tipo_trabajo=item.id_tipo_trabajo._id;
    
    
            //establecemos tecnicos
            let arreglo_tecnicos=item.id_tecnicos;
    
    
            console.log(item.id_tecnicos);
            let nuevo_array=[];
    
            arreglo_tecnicos.map(function(x){
                nuevo_array.push(x.ref_tecnico);
            });
    
            this.editedItem.id_tecnicos=nuevo_array;
    
    
            //establecemos tipo equipo
    
            if(item.id_tipo_equipo){
                this.editedItem.id_tipo_equipo=item.id_tipo_equipo._id;
            }
          
    
           console.log(item);
    
            //accesorios instalados
    
            if(item.id_accesorios_instalados){
    
    
            console.log(item.id_accesorios_instalados);
    
              let array_acces=[];
             let arr_accesorios=item.id_accesorios_instalados;
           
    
            arr_accesorios.map(function(x){
                array_acces.push(x.ref_accesorio);
            });
    
            this.editedItem.id_accesorios_instalados=array_acces;
          }
    
    
    
    
    
            this.dialog = true;
          },
    
          deleteItem (item) {
            this.editedIndex = this.itemsBitacora.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
          },
    
          deleteItemConfirm () {
    
                let config={headers:{token:this.token}};
                
                        axios.put(`bitacoras/${this.editedItem._id}`,
                                  { '_id':this.editedItem._id,'activo':0},
                                config
                            ).then(response=>{
                          
                            console.log(response);
                         
    
                            this.closeDelete();
                            this.listar();
    
                      }).catch(e=>{
                            console.log(e)
                      }); 
    
          },
    
          close () {
            this.dialog = false
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
          },
    
          closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
          },
    
          save () {
            if (this.editedIndex > -1) {
    
              let me=this;
    
              //edicion de bitacora
             
               if( 
                    (this.editedItem.fecha!='')&&(this.editedItem.hora_establecida!='')&&
                    (this.editedItem.id_bitacora_cliente!='')&&(this.editedItem.contacto!='')
                    &&(this.editedItem.direccion!='')&&(this.editedItem.id_tipo_trabajo!='')&&
                    (this.editedItem.status!='')
              ){
    
    
                //si ya tiene los ceros el reloj de horas verificar
    
                let texto_hora_e=this.editedItem.hora_establecida;
                let arr_establecida = texto_hora_e.split(':');
                if(arr_establecida.length == 2){
                  this.editedItem.hora_establecida=this.editedItem.hora_establecida+':00';
                }
    
    
                  //verificar hora de llegada 
    
                 let texto_hora_lle=this.editedItem.hora_llegada_tecnico;
                let arr_llegada = texto_hora_lle.split(':');
                if(arr_llegada.length == 2){
                  this.editedItem.hora_llegada_tecnico=this.editedItem.hora_llegada_tecnico+':00';
                }
    
    
                //verificar hora de inicio 
    
                 let texto_hora_i=this.editedItem.hora_inicio;
                let arr_inicio = texto_hora_i.split(':');
                if(arr_inicio.length == 2){
                  this.editedItem.hora_inicio=this.editedItem.hora_inicio+':00';
                }
    
    
                 let texto_hora_f=this.editedItem.hora_fin;
                let arr_fin = texto_hora_f.split(':');
                if(arr_fin.length == 2){
                  this.editedItem.hora_fin=this.editedItem.hora_fin+':00';
                }
    
                let fecha_insertar=this.editedItem.fecha+'T'+this.editedItem.hora_establecida+'.000+00:00';
    
              //  alert(fecha_insertar);
    
    
                //calculamos tiempo de espera
    
                if((this.editedItem.hora_inicio!='')&&(this.editedItem.hora_llegada_tecnico!='')){
    
    
                  
                  let startTime=moment(this.editedItem.hora_llegada_tecnico, "HH:mm:ss");
                  let endTime=moment(this.editedItem.hora_inicio, "HH:mm:ss");
    
                    if(startTime.isBefore(endTime)){
    
                    let duration = moment.duration(endTime.diff(startTime));
                    let espera_minutos = parseInt(duration.asMinutes());
                    espera_minutos=espera_minutos/60;
                    espera_minutos=parseFloat(espera_minutos).toFixed(2);
    
                    this.editedItem.tiempo_espera=espera_minutos; //ya en horas
                  }else{
                     this.editedItem.tiempo_espera=0;
                  }
    
                }
    
    
                if((this.editedItem.anio=='.') || (this.editedItem.anio=='')){
                  this.editedItem.anio=2022;
                }
    
    
                let config={headers:{token:this.token}};
                this.mostrarLoading({titulo:'Actualizando a datos'});
    
                let item_actualizar= { 
                                              'fecha':fecha_insertar,
                                              'hora_establecida':this.editedItem.hora_establecida,
                                              'hora_inicio':this.editedItem.hora_inicio,
                                              'hora_fin':this.editedItem.hora_fin,
                                              'tiempo_espera':this.editedItem.tiempo_espera,
                                              'hora_llegada_tecnico':this.editedItem.hora_llegada_tecnico,
                                              'id_bitacora_cliente':this.editedItem.id_bitacora_cliente,
    
                                              'contacto':this.editedItem.contacto,
                                              'direccion':this.editedItem.direccion,
    
                                              'id_tipo_trabajo':this.editedItem.id_tipo_trabajo,
                                              'descripcion_trabajo':this.editedItem.descripcion_trabajo,
                                              'status':this.editedItem.status,
    
                                              'etiqueta':this.editedItem.etiqueta,
    
                                              'placa_anterior':this.editedItem.placa_anterior,
                                              'serie_anterior':this.editedItem.serie_anterior,
                                              'imei_anterior':this.editedItem.imei_anterior,
    
    
                                              'id_avl':this.editedItem.id_avl,
                                              'nombre':this.editedItem.nombre,
                                              'placa':this.editedItem.placa,
                                              'flota':this.editedItem.flota,
                                            
                                              'observacion_operador':this.editedItem.observacion_operador,
                                              'estilo':this.editedItem.estilo,
                                              'marca':this.editedItem.marca,
    
                                              'modelo':this.editedItem.modelo,
                                              'color':this.editedItem.color,
                                              'anio':this.editedItem.anio,
                                              'chasis':this.editedItem.chasis,
    
    
                                              'serie':this.editedItem.serie,
                                              'imei':this.editedItem.imei,
                                              'simcard':this.editedItem.simcard,
                                              'simcard_anterior':this.editedItem.simcard_anterior,
                                              'proveedor_simcard':this.editedItem.proveedor_simcard,
    
                                              'ubicacion_equipo':this.editedItem.ubicacion_equipo,
                                              'observacion_tecnico':this.editedItem.observacion_tecnico,
                                              'activo':this.editedItem.activo,
                                              'procesado_avl':this.editedItem.procesado_avl,
                                              'editado':this.usuario.nombre_ejecutivo+' a las:'+ me.ConvertirFecha(new Date())
    
                                              };
    
    
                     if(this.editedItem.id_tipo_equipo!=''){
                          item_actualizar.id_tipo_equipo=this.editedItem.id_tipo_equipo;
                      }  
    
    
                      
    
                      if(this.editedItem.id_accesorios_instalados!=''){
    
                                let separa=this.editedItem.id_accesorios_instalados;
                                separa=separa.toString();
                                separa=separa.split(",");
    
                                let ArrayEnviarAcesorios=[];
    
                              for(let i=0; i < separa.length; i++){
                                console.log('datos:ref_accesorio:'+separa[i]);
                                ArrayEnviarAcesorios.push({ref_accesorio:separa[i]});
                              }
    
    
                                console.log('datos de accesorios');
                                console.log(ArrayEnviarAcesorios);
                                item_actualizar.id_accesorios_instalados=ArrayEnviarAcesorios;
                       } 
                  
    
                        if(this.editedItem.id_tecnicos!=''){
    
                              let separa=this.editedItem.id_tecnicos;
                              separa=separa.toString();
                              separa=separa.split(",");
    
                              let ArrayEnviarTecnicos=[];
    
                            for(let i=0; i < separa.length; i++){
                              console.log('datos:ref_tecnico:'+separa[i]);
                              ArrayEnviarTecnicos.push({ref_tecnico:separa[i]});
                            }
    
    
                              console.log('datos de tecnicos');
                              console.log(ArrayEnviarTecnicos);
                                item_actualizar.id_tecnicos=ArrayEnviarTecnicos;
                      }
                       
                       
    
    
              
                            let id=this.editedItem._id;
    
                  
                          axios.put(`bitacoras/${id}`,
                                     item_actualizar,
                                        config
                                  ).then(response=>{
    
                                     this.close();
                                    me.ocultarLoading();
                                    console.log(response);
                                    this.listar();
    
                                  }).catch(e=>{
                                        console.log(e)
                                      
                                  });
    
    
    
    
    
    
                
    
              }else{
    
                alert('faltan campos obligatorios');
              }
    
    
    
    
            } else {
              //nueva bitacora 
          
               let me=this;
    
              if( 
                    (this.editedItem.fecha!='')&&(this.editedItem.hora_establecida!='')&&
                    (this.editedItem.id_bitacora_cliente!='')&&(this.editedItem.contacto!='')
                    &&(this.editedItem.direccion!='')&&(this.editedItem.id_tipo_trabajo!='')&&
                    (this.editedItem.status!='')
              ){ 
    
    
                
                //si ya tiene los ceros el reloj de horas verificar
                if(this.editedItem.hora_establecida!=''){  
                              this.editedItem.hora_establecida=this.editedItem.hora_establecida+":00";      
                }
    
    
                  //verificar hora de llegada 
                if(this.editedItem.hora_llegada_tecnico!=''){
                            this.editedItem.hora_llegada_tecnico=this.editedItem.hora_llegada_tecnico+":00";
                }
    
    
                //verificar hora de inicio 
                  if(this.editedItem.hora_inicio!=''){
                          this.editedItem.hora_inicio=this.editedItem.hora_inicio+":00";
                  }
    
    
              //verificar hora fin
                  if(this.editedItem.hora_fin!=''){
                                this.editedItem.hora_fin=this.editedItem.hora_fin+":00";
                  }
    
                  
                //calculamos tiempo de espera
    
                if((this.editedItem.hora_inicio!='')&&(this.editedItem.hora_llegada_tecnico!='')){
    
                  
                  console.log('llego tecnico:'+this.editedItem.hora_llegada_tecnico);
                  console.log('inciio trabajo:'+this.editedItem.hora_inicio);
    
    
                  let startTime=moment(this.editedItem.hora_llegada_tecnico, "HH:mm:ss");
                  let endTime=moment(this.editedItem.hora_inicio, "HH:mm:ss");
    
                    if(startTime.isBefore(endTime)){
    
                    let duration = moment.duration(endTime.diff(startTime));
                    let espera_minutos = parseInt(duration.asMinutes());
                    espera_minutos=espera_minutos/60;
                    espera_minutos=parseFloat(espera_minutos).toFixed(2);
    
                    this.editedItem.tiempo_espera=espera_minutos; //ya en horas
                  }else{
                     this.editedItem.tiempo_espera=0;
                  }
    
                }
    
                if((this.editedItem.anio=='.') || (this.editedItem.anio=='')){
                  this.editedItem.anio=2022;
                }
    
    
    
                  
              //  let fecha_insertar=this.editedItem.fecha+' '+this.editedItem.hora_establecida;
                let fecha_insertar=this.editedItem.fecha+'T'+this.editedItem.hora_establecida+'.000+00:00';
    
                  let config={headers:{token:this.token}};
                  this.mostrarLoading({titulo:'Accediendo a datos'});
    
                  let item_insertar= { 
                                              'fecha':fecha_insertar,
                                              'hora_establecida':this.editedItem.hora_establecida,
                                              'hora_inicio':this.editedItem.hora_inicio,
                                              'hora_fin':this.editedItem.hora_fin,
                                              'tiempo_espera':this.editedItem.tiempo_espera,
                                              'hora_llegada_tecnico':this.editedItem.hora_llegada_tecnico,
    
                                              'id_bitacora_cliente':this.editedItem.id_bitacora_cliente,
    
                                              'contacto':this.editedItem.contacto,
                                              'direccion':this.editedItem.direccion,
    
                                              'id_tipo_trabajo':this.editedItem.id_tipo_trabajo,
                                              'descripcion_trabajo':this.editedItem.descripcion_trabajo,
                                              'status':this.editedItem.status,
    
                                              'etiqueta':this.editedItem.etiqueta,
    
                                                  
                                              'placa_anterior':this.editedItem.placa_anterior,
                                              'serie_anterior':this.editedItem.serie_anterior,
                                              'imei_anterior':this.editedItem.imei_anterior,
    
                                              'id_avl':this.editedItem.id_avl,
                                              'nombre':this.editedItem.nombre,
                                              'placa':this.editedItem.placa,
                                              'flota':this.editedItem.flota,
                                            
                                              'observacion_operador':this.editedItem.observacion_operador,
                                              'estilo':this.editedItem.estilo,
                                              'marca':this.editedItem.marca,
    
                                              'modelo':this.editedItem.modelo,
                                              'color':this.editedItem.color,
                                              'anio':this.editedItem.anio,
                                              'chasis':this.editedItem.chasis,
    
    
                                              'serie':this.editedItem.serie,
                                              'imei':this.editedItem.imei,
                                              'simcard':this.editedItem.simcard,
                                              'simcard_anterior':this.editedItem.simcard_anterior,
                                              'proveedor_simcard':this.editedItem.proveedor_simcard,
    
                                              'ubicacion_equipo':this.editedItem.ubicacion_equipo,
                                              'observacion_tecnico':this.editedItem.observacion_tecnico,
                                              'activo':this.editedItem.activo,
                                              'procesado_avl':this.editedItem.procesado_avl,
                                              'editado':this.usuario.nombre_ejecutivo+' a las:'+ me.ConvertirFecha(new Date())
    
                                              };
    
    
                      if(this.editedItem.id_tipo_equipo!=''){
                          item_insertar.id_tipo_equipo=this.editedItem.id_tipo_equipo;
                      }     
                      
                      
                      if(this.editedItem.id_accesorios_instalados!=''){
    
                                let separa=this.editedItem.id_accesorios_instalados;
                                separa=separa.toString();
                                separa=separa.split(",");
    
                                let ArrayEnviarAcesorios=[];
    
                              for(let i=0; i < separa.length; i++){
                                console.log('datos:ref_accesorio:'+separa[i]);
                                ArrayEnviarAcesorios.push({ref_accesorio:separa[i]});
                              }
    
    
                                console.log('datos de accesorios');
                                console.log(ArrayEnviarAcesorios);
                                item_insertar.id_accesorios_instalados=ArrayEnviarAcesorios;
                       }  
    
                  
                       if(this.editedItem.id_tecnicos!=''){
    
                        let separa=this.editedItem.id_tecnicos;
                        separa=separa.toString();
                        separa=separa.split(",");
    
                        let ArrayEnviarTecnicos=[];
    
                      for(let i=0; i < separa.length; i++){
                        console.log('datos:ref_tecnico:'+separa[i]);
                        ArrayEnviarTecnicos.push({ref_tecnico:separa[i]});
                      }
    
    
                         console.log('datos de tecnicos');
                         console.log(ArrayEnviarTecnicos);
                          item_insertar.id_tecnicos=ArrayEnviarTecnicos;
                      }  
    
    
                  axios.post('nueva-bitacora', item_insertar,
                                        config
                                  ).then(response=>{
    
                                     this.close();
                                    me.ocultarLoading();
                                    console.log(response);
                                    this.listar();
    
                                  }).catch(e=>{
                                        console.log(e)
                                      
                                  });
    
              }else{
    
                alert('faltan campos obligatorios');
              }
    
    
            }
          
          },
    
    
          
          ConvertirFecha(fecha_parametro){
    
            let texto=new Date(fecha_parametro).toLocaleDateString()+" "+new Date(fecha_parametro).toLocaleTimeString();
    
            return(texto);
    
          },
    
    
          //buscaremos si hay ultimos datos de ese id de AVL ultimos registro. 
          BuscarDatosId(){
    
    
            if(this.id_avl_buscar!=''){
    
                        let me=this;
                        let config={headers:{token:this.token}};
                          
                                  axios.get(`bitacorabusqueda/${this.id_avl_buscar}`,
                                          config
                                      ).then(response=>{
                                    
                                      console.log(response);
    
    
                                    if((response.data)&&(response.data.length==1)){
    
                                    
                                      let datos_respuesta=response.data[0];
    
                                      if(datos_respuesta.placa){
                                      
                                                        me.editedItem.id_avl=datos_respuesta.id_avl;
                                                        me.editedItem.nombre=datos_respuesta.nombre;
                                                        me.editedItem.placa=datos_respuesta.placa;
                                                        me.editedItem.flota=datos_respuesta.flota;
    
                                                        me.editedItem.estilo=datos_respuesta.estilo;
                                                        me.editedItem.marca=datos_respuesta.marca;
    
                                                        me.editedItem.modelo=datos_respuesta.modelo;
                                                        me.editedItem.color=datos_respuesta.color;
                                                        me.editedItem.anio=datos_respuesta.anio;
                                                        me.editedItem.chasis=datos_respuesta.chasis;
    
    
                                                        me.editedItem.serie=datos_respuesta.serie;
                                                        me.editedItem.imei=datos_respuesta.imei;
                                                        me.editedItem.simcard=datos_respuesta.simcard;
                                                        me.editedItem.proveedor_simcard=datos_respuesta.proveedor_simcard;
                                                        me.editedItem.ubicacion_equipo=datos_respuesta.ubicacion_equipo;
    
                                                        me.editedItem.id_tipo_equipo=datos_respuesta.id_tipo_equipo._id;
    
    
                                                
                                                        //accesorios instalados
                                                          let arreglo_accesorios=datos_respuesta.id_accesorios_instalados;
                                                          let array_accesorios_=[];
    
                                                          arreglo_accesorios.map(function(x){
                                                              array_accesorios_.push(x.ref_accesorio);
                                                          });
    
                                                          this.editedItem.id_accesorios_instalados=array_accesorios_;
                                                    
                                        
    
    
                                      }else{
                                        alert('no hay datos de ese equipo');
                                      }
    
                                      }
    
                                    
                                  
    
                                }).catch(e=>{
                                      console.log(e)
                                });
    
          }
    
    
          },
    
    
          CalcularFechaHoy(){
                let fecha=new Date();
                 fecha.toLocaleString('en-US', { timeZone: 'America/Managua'});
                let mes=fecha.getMonth()+1;
                if(mes<10)mes='0'+mes;
                let dia=fecha.getDate();
                if(dia<10)dia='0'+dia;
                let textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;
    
                return(textofecha);
          },
    
    
    
          VerDetalleBitacora(item){
    
            this.dialogDetalleBitacora=true;
            this.viewItem=item;
    
            console.log(item);
    
    
          },
    
          
    
         renderizarTecnico(_id){
              let texto='';
    
               this.ArrayTenicos.map(function(x){
                    if(x._id==_id){ texto=x.nombre_tecnico;}
               });
    
               return(texto);
    
          },
    
    
          renderizarAccesorio(_id){
    
            let texto='';
    
               this.ArrayAccesorios.map(function(x){
                    if(x._id==_id){ texto=x.nombre_accesorio;}
               });
    
               return(texto);
    
    
          },
    
          nuevoContacto(){
            this.editedContacto={};
            this.dialogNuevoContacto=true;
           
          },
    
    
    
          GuardarContactos(){
    
            if((this.editedContacto.nombre_contacto!='')&&(this.editedItem.id_bitacora_cliente!='')){
    
            let me=this;
    
            this.itemsContact.push(this.editedContacto);
    
    
             let config={headers:{token:this.token}};
                       
                 
                     
                this.mostrarLoading({titulo:'Accediendo a datos'});
    
              axios.put(`clientes/${this.editedItem.id_bitacora_cliente}`,
                { 
                      'contactos':this.itemsContact,
                  },
                config
    
                  ).then(response=>{
                
                  console.log(response);
                  me.ocultarLoading();
                  this.editedContacto={};
                
                  this.listarClientes();
                  this.dialogNuevoContacto=false;
    
            }).catch(e=>{
                  console.log(e)
                  this.mensaje=e
            });
    
          }else{
            alert('faltan campos');
          }
    
    
          },
    
    
           nuevaDireccion(){
            this.editedDireccion={};
            this.dialogNuevaDireccion=true;
           
          },
    
    
          openGoogleMap(item){
            const urlSuffix = item.latitud +','+ item.longitud;
            window.open(
              "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
              "_blank"
            );
            },
    
           reloadMap(){
              setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 250);
           },
    
            onMapClick(e) {
            this.editedDireccion.latitud = e.latlng.lat;
            this.editedDireccion.longitud = e.latlng.lng;
    
            this.point={lat: e.latlng.lat, lng: e.latlng.lng};
          },
    
           GuardarDireccion(){
                  let me=this;
                   let procesar=true;
    
                  if((this.editedDireccion.tipo=="")||(this.editedDireccion.texto=="")||(this.editedItem.id_bitacora_cliente=='')){
                    alert('faltan campos');
                        procesar=false;
                  }else{
                      this.itemsDirecciones.push(this.editedDireccion);
                  }
    
                  if((this.editedDireccion.texto=='') || (this.editedDireccion.texto==null)){
                    procesar=false;
                    alert('faltan campos');
                  }
    
    
    
        
                 if(procesar==true){
    
    
    
                          //actualizar datos de item
                                  let config={headers:{token:this.token}};
                                
                              
                                  this.mostrarLoading({titulo:'Accediendo a datos'});
    
                                  axios.put(`clientes/${this.editedItem.id_bitacora_cliente}`,
                                    { 
                                        'direcciones':this.itemsDirecciones,
                                    },
                                    config
    
                                      ).then(response=>{
                                    
                                      console.log(response);
                                      me.ocultarLoading();
                                      this.editedDireccion={};
                                  
                                      this.listarClientes();
                                      me.dialogEdicionDirecciones=false;
                                      me.dialogNuevaDireccion=false;
    
                                }).catch(e=>{
                                      console.log(e)
                                      this.mensaje=e
                                });
    
                    }
    
                  
    
    
           },
    
    
           VerMapa(item){
    
             let me=this;
    
             let direcciones_locales=item.id_bitacora_cliente.direcciones;
    
             console.log(item.id_bitacora_cliente.direcciones);
    
              direcciones_locales.map(function(x){
    
                if(item.direccion==x.texto){
    
                  if((x.latitud!='')&&(x.longitud!='')){
                        me.openGoogleMap(x);
                  }
                
                }
    
              });
             
    
    
    
           },
    
           DescargarBitacora(item){
    
               let config={headers:{ token:this.token}};
               let me=this;
               this.mostrarLoading({titulo:'procesando datos'});
    
                  axios.post('bitacoraExportar',{ 
                                            'bitacora':item
                                          },config
                                      ).then(response=>{
    
                                          console.log(response);
    
                                          if(response.data){
                                           
    
                                            if(response.data.archivo){
                                                let archivo_descargar=response.data.archivo;
                                                me.Descargar(archivo_descargar);
                                            }
                                          }
    
                                          
                                          me.ocultarLoading();
    
                          }).catch(e=>{ console.log(e) });  
    
           },
    
           Descargar(title){
             
            // title="DIAGRAMA.xlsx";
    
             console.log('vamos a descargar:'+title);
    
               let me =this;
    
                  axios.post('download', {fileName: title},
                              {responseType:'blob'},
              
                        ).then(function(response){
    
                          
                            var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                            var fileLink = document.createElement('a');
                          
                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', title);
                            document.body.appendChild(fileLink);
                          
                            fileLink.click();
    
    
                            me.ocultarLoading();
                            
                        }).catch(function(error){
                            console.log(error);
                        });
    
           },
    
    
           HistorialBitacora(item){
    
    
              this.itemHistorial={
                'id_avl':item.id_avl,
                'placa':item.placa
              };
    
              this.itemsHistorialBitacora=[];
    
    
               let config={headers:{ token:this.token}};
               let me=this;
               this.mostrarLoading({titulo:'procesando datos'});
    
    
                   axios.post('bitacorahistorial',{
                                        'placa':item.placa,
                                        'id_avl':item.id_avl
                                        }
                                      , config
                                      ).then(function(response){
                                        console.log(response);
    
                                         let respuesta=response.data
    
                                          if((respuesta)&&(respuesta.length >0)){
                                             me.itemsHistorialBitacora=response.data;
                                          }
    
                                       
                                          me.ocultarLoading();
                                          me.dialogHistorialBitacora=true;
                            
                                        }).catch(function(error){
                                      console.log(error);
                                    });
    
    
    
           },
    
    
    
          //subir scaner pdf de diagramas de trabajo
    
           async submitFiles(){
    
              if(this.status_procesado1==false){
    
                    let me=this;
    
                        if(this.filehoja1){
    
                                this.mostrarLoading({titulo:'procesando datos'});
                                let formData = new FormData();
    
                                  //enviar achivo unico
                               
                                  formData.append("file0", this.filehoja1); 
                                  this.status_procesado1=true;
                                
                             await axios.post(`uploadpdfdiagrama/${this.editedItem._id}`, formData)
                                    .then(response => {
                                        console.log("Success!");
                                        console.log(response.data);
                                          me.listar();
                                          me.ocultarLoading();
                                    })
                                    .catch(error => {
                                        console.log({ error });
                                    });
                          } else {
                              alert("no hay archivos que subir");
                             
                          }
    
    
    
    
              }
    
    
               if(this.status_procesado2==false){
    
                    let me=this;
    
                        if(this.filehoja2){
    
                                this.mostrarLoading({titulo:'procesando datos'});
                                let formData = new FormData();
    
                                  //enviar achivo unico
                               
                                  formData.append("file1", this.filehoja2); 
                                  this.status_procesado2=true;
                                
                             await axios.post(`uploadpdforden/${this.editedItem._id}`, formData)
                                    .then(response => {
                                        console.log("Success!");
                                        console.log(response.data);
                                          me.ocultarLoading();
                                          me.listar();
                                    })
                                    .catch(error => {
                                        console.log({ error });
                                    });
                          } else {
                              alert("no hay archivos que subir");
                             
                          }
    
    
    
    
              }
                
          },
    
    
          DescargarPdf(title){
             
    
             console.log('vamos a descargar:'+title);
    
               let me =this;
    
                  axios.post('downloadpdf', {fileName: title},
                              {responseType:'blob'},
              
                        ).then(function(response){
    
                          
                            var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'applications/vnd.pdf'}));
                            var fileLink = document.createElement('a');
                          
                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', title);
                            document.body.appendChild(fileLink);
                          
                            fileLink.click();
    
    
                            me.ocultarLoading();
                            
                        }).catch(function(error){
                            console.log(error);
                        });
    
           },
    
           CrearMarcador(){
                    this.point={lat: this.editedDireccion.latitud, lng: this.editedDireccion.longitud};
                    this.center=latLng(this.editedDireccion.latitud,this.editedDireccion.longitud);
           }
        
    
    
    
    
    
    
    
        },
      }
    </script>